import React, { useState, useEffect } from 'react';
import { TextField, Button, Table, TableBody, TableCell, TableHead, TableRow, Paper, Container, Typography, Box } from '@mui/material';
import axios from 'axios';

const PriceAnalysis = () => {
  const [dishes, setDishes] = useState([]);
  const [newDish, setNewDish] = useState({
    item: '',
    name: '',
    htPrice: '',
    minPrice: '',
    maxPrice: '',
    analyst: 'Kitchen'
  });

  useEffect(() => {
    // Dummy API call to fetch dish data
    axios.get('/api/dishes')
      .then(response => {
        // Replace the following line with `setDishes(response.data)` when using actual API
        setDishes([...dishes]); 
      });
  }, []);

  useEffect(() => {
    // Calculate HT price based on min and max price
    if (newDish.minPrice && newDish.maxPrice) {
      const calculatedHTPrice = (parseFloat(newDish.minPrice) + parseFloat(newDish.maxPrice)) / 2;
      setNewDish({ ...newDish, htPrice: calculatedHTPrice.toFixed(2) });
    }
  }, [newDish.minPrice, newDish.maxPrice]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewDish({ ...newDish, [name]: value });
  };

  const handleAddDish = () => {
    setDishes([...dishes, newDish]);
    setNewDish({
      item: '',
      name: '',
      htPrice: '',
      minPrice: '',
      maxPrice: '',
      analyst: 'Kitchen'
    });
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>Price Analysis</Typography>
      
      

      <TextField
        label="Dish Name"
        value={newDish.name}
        name="name"
        onChange={handleInputChange}
        fullWidth
        margin="normal"
      />

      <TextField
        label="HT Price"
        value={newDish.htPrice}
        InputProps={{ readOnly: true }}
        fullWidth
        margin="normal"
      />

      <TextField
        label="Min Price"
        value={newDish.minPrice}
        name="minPrice"
        onChange={handleInputChange}
        fullWidth
        margin="normal"
      />

      <TextField
        label="Max Price"
        value={newDish.maxPrice}
        name="maxPrice"
        onChange={handleInputChange}
        fullWidth
        margin="normal"
      />

      <TextField
        label="Analysis Done by"
        value={newDish.analyst}
        InputProps={{ readOnly: true }}
        fullWidth
        margin="normal"
      />

      <Box mt={2} mb={2}>
        <Button variant="contained" color="primary" onClick={handleAddDish}>Search Dish</Button>
      </Box>

      <Box mt={2} mb={2}>
        <Table component={Paper}>
          <TableHead>
            <TableRow>
              
              <TableCell>Dish Name</TableCell>
              <TableCell>HT Price</TableCell>
              <TableCell>Min Price</TableCell>
              <TableCell>Max Price</TableCell>
              <TableCell>Analysis Done by</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dishes.map((dish, index) => (
              <TableRow key={index}>
                
                <TableCell>{dish.name}</TableCell>
                <TableCell>{dish.htPrice}</TableCell>
                <TableCell>{dish.minPrice}</TableCell>
                <TableCell>{dish.maxPrice}</TableCell>
                <TableCell>{dish.analyst}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Container>
  );
};

export default PriceAnalysis;

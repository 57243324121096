import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Collapse,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { Search, Edit, Add, Delete } from '@mui/icons-material';
import axios from 'axios';

const BASE_URL = 'https://pricingappbackend.homemadefoodshop.com'; // Base URL for API calls

const IngredientsPage = () => {
  const [ingredients, setIngredients] = useState([]);
  const [categories, setCategories] = useState([]);
  const [sub_categories, setSub_categories] = useState([]);
  const [open, setOpen] = useState(false);
  const [newIngredient, setNewIngredient] = useState({
    ingredient_name: '',
    ingredient_description: '',
    ingredient_price: '',
    ingredient_quantity: '',
    category: '',
    sub_category: '',
  });
  const [editIngredient, setEditIngredient] = useState(null);

  useEffect(() => {
    fetchIngredients();
    fetchCategories();
    fetchAllSub_categories();
  }, []);

  const fetchIngredients = () => {
    axios.get(`${BASE_URL}/ingredients/getall`)
      .then(response => {
        if (response.data.success) {
          setIngredients(response.data.data);
        } else {
          console.error('Failed to fetch ingredients:', response.data.message);
        }
      })
      .catch(error => console.error('Error fetching ingredients:', error));
  };

  const fetchCategories = () => {
    axios.get(`${BASE_URL}/Ingcategory/getallcategories`)
      .then(response => {
        if (response.data.success) {
          setCategories(response.data.data);
        } else {
          console.error('Failed to fetch categories:', response.data.message);
        }
      })
      .catch(error => console.error('Error fetching categories:', error));
  };

  const fetchAllSub_categories = () => {
    axios.get(`${BASE_URL}/ingscategory/getall`)
      .then(response => {
        if (response.data.success) {
          setSub_categories(response.data.data);
        } else {
          console.error('Failed to fetch subcategories:', response.data.message);
        }
      })
      .catch(error => console.error('Error fetching subcategories:', error));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewIngredient({ ...newIngredient, [name]: value });
  };

  const handleSubmit = () => {
    const ingredientToAdd = {
      ...newIngredient,
      category: newIngredient.category,
      sub_category: newIngredient.sub_category,
    };
    
    axios.post(`${BASE_URL}/ingredients/addIngredients`, ingredientToAdd)
      .then(response => {
        if (response.data.success) {
          setIngredients([...ingredients, response.data.data]);
          resetForm();
          setOpen(false);
        } else {
          console.error('Failed to add ingredient:', response.data.message);
        }
      })
      .catch(error => console.error('Error adding ingredient:', error));
  };

  const handleEditClick = (ingredient) => {
    setEditIngredient(ingredient);
    setNewIngredient({
      ingredient_name: ingredient.ingredient_name,
      ingredient_description: ingredient.ingredient_description,
      ingredient_price: ingredient.ingredient_price,
      ingredient_quantity: ingredient.ingredient_quantity,
      category: ingredient.category,
      sub_category: ingredient.sub_category,
    });
    setOpen(true);
  };

  const handleEditSubmit = () => {
    const updatedIngredient = {
      ...editIngredient,
      ingredient_name: newIngredient.ingredient_name,
      ingredient_description: newIngredient.ingredient_description,
      ingredient_price: newIngredient.ingredient_price,
      ingredient_quantity: newIngredient.ingredient_quantity,
      category: newIngredient.category,
      sub_category: newIngredient.sub_category,
    };

    axios.put(`${BASE_URL}/ingredients/modifyingredients`, updatedIngredient)
      .then(response => {
        if (response.data.success) {
          setIngredients(ingredients.map(ing => 
            ing.ingredient_id === editIngredient.ingredient_id ? response.data.data : ing
          ));
          resetForm();
          setOpen(false);
        } else {
          console.error('Failed to update ingredient:', response.data.message);
        }
      })
      .catch(error => console.error('Error updating ingredient:', error));
  };

  const handleDelete = async (ingredientId) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this ingredient?");
    if (confirmDelete) {
      try {
        const response = await axios.delete(`${BASE_URL}/ingredients/deleteIngredient`, {
          params: { Ingredientid: ingredientId }
        });
        if (response.status === 200) {
          // Update your state to remove the deleted ingredient
        }
      } catch (error) {
        console.error('Error deleting ingredient:', error);
        alert('Failed to delete the ingredient. Please try again.');
      }
    }
  };
  

  const resetForm = () => {
    setNewIngredient({
      ingredient_name: '',
      ingredient_description: '',
      ingredient_price: '',
      ingredient_quantity: '',
      category: '',
      sub_category: '',
    });
    setEditIngredient(null);
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4" gutterBottom>Ingredients</Typography>
      <Box display="flex" alignItems="center" mb={2}>
        <TextField
          label="Search Ingredient"
          variant="outlined"
          size="small"
          sx={{ marginRight: 1 }}
        />
        <Button
          variant="contained"
          onClick={() => {} /* Implement search functionality if needed */}
          startIcon={<Search />}
        >
          Search
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpen(!open)}
          startIcon={<Add />}
          sx={{ marginLeft: 'auto' }}
        >
          Add
        </Button>
      </Box>

      <Collapse in={open}>
        <Box mb={2}>
          <Typography variant="h6" gutterBottom>{editIngredient ? 'Edit Ingredient' : 'Add New Ingredient'}</Typography>
          <FormControl variant="outlined" size="small" fullWidth sx={{ marginBottom: 1 }}>
            <InputLabel>Category</InputLabel>
            <Select
              name="category"
              value={newIngredient.category}
              onChange={handleChange}
              label="Category"
            >
              {categories.map((cat, index) => (
                <MenuItem key={index} value={cat.ingcategoryname}>{cat.ingcategoryname}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined" size="small" fullWidth sx={{ marginBottom: 1 }}>
            <InputLabel>Subcategory</InputLabel>
            <Select
              name="sub_category"
              value={newIngredient.sub_category}
              onChange={handleChange}
              label="Subcategory"
            >
              {sub_categories.map((subcat, index) => (
                <MenuItem key={index} value={subcat.ingscategoryname}>{subcat.ingscategoryname}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            name="ingredient_name"
            label="Ingredient Name"
            variant="outlined"
            size="small"
            fullWidth
            value={newIngredient.ingredient_name}
            onChange={handleChange}
            sx={{ marginBottom: 1 }}
          />
          <TextField
            name="ingredient_description"
            label="Description"
            variant="outlined"
            size="small"
            fullWidth
            value={newIngredient.ingredient_description}
            onChange={handleChange}
            sx={{ marginBottom: 1 }}
          />
          <TextField
            name="ingredient_price"
            label="Price"
            variant="outlined"
            size="small"
            fullWidth
            value={newIngredient.ingredient_price}
            onChange={handleChange}
            sx={{ marginBottom: 1 }}
          />
          <TextField
            name="ingredient_quantity"
            label="Quantity"
            variant="outlined"
            size="small"
            fullWidth
            value={newIngredient.ingredient_quantity}
            onChange={handleChange}
            sx={{ marginBottom: 1 }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={editIngredient ? handleEditSubmit : handleSubmit}
          >
            {editIngredient ? 'Update Ingredient' : 'Add Ingredient'}
          </Button>
        </Box>
      </Collapse>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Subcategory</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ingredients.map((ingredient) => (
              <TableRow key={ingredient.ingredient_id}>
                <TableCell>{ingredient.ingredient_name}</TableCell>
                <TableCell>{ingredient.ingredient_description}</TableCell>
                <TableCell>{ingredient.ingredient_price}</TableCell>
                <TableCell>{ingredient.ingredient_quantity}</TableCell>
                <TableCell>{ingredient.category}</TableCell>
                <TableCell>{ingredient.sub_category}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleEditClick(ingredient)}>
                    <Edit />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(ingredient.ingredient_id)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default IngredientsPage;

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Signin from './pages/Signin';
import IngredientsForm from './pages/IngredientsForm';
import MLIPage from './pages/MLI';
// import DishAnalysisPage from './pages/DishAnalysisPage';
// import FoodItemsPage from './pages/FoodItemsPage';
// import ItemPricesPage from './pages/ItemPricesPage';
// import MLDPage from './pages/MLDpage';
// import PricingPage from './pages/PricingPage';
import IngredientCategories from './pages/IngredientCategories';
import SubCategories from './pages/SubCategories';
import IngredientsPage from './pages/IngredientsPage';
import ItemCategoriesPage from './pages/ItemCategoriesPage';
import ItemSubcategoriesPage from './pages/ItemSubcategoriesPage';
import ItemsPage from './pages/ItemsPage'; 
import IngredientPrices from './pages/IngredientPrices';
import Analysts from './pages/Analysts';
import RecipeDetails from './pages/RecipeDetails';
import PriceAnalysis from './pages/PriceAnalysis';
import Register from './pages/Register';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Signin />} />
        <Route path="/mli" element={<MLIPage />} />
        <Route path="/register" element={<Register />} />
        <Route path="/main" element={<IngredientsForm showSidebar={true} />} /> 
        <Route path="/ingredient-categories" element={<IngredientCategories/>} />
        <Route path="/ingredient-subcategories" element={<SubCategories/>} />
        <Route path="/ingredients" element={<IngredientsPage/>} />
        <Route path="/item-categories" element={<ItemCategoriesPage/>} />
        <Route path="/item-subcategories" element={<ItemSubcategoriesPage/>} />
        <Route path="/items" element={<ItemsPage/>} />
        <Route path="/ingredients-price" element={<IngredientPrices/>} />
        <Route path="/analyst" element={<Analysts/>} />
        <Route path="/recipe-details" element={<RecipeDetails/>} />
        <Route path="/price-analysis" element={<PriceAnalysis/>} />
        
        
      </Routes>
    </Router>
  );
};

export default App;

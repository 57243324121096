import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  IconButton,
  Collapse,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
  Alert,
} from '@mui/material';
import { Search, Edit, Add, Delete } from '@mui/icons-material';
import axios from 'axios';

const BASE_URL = 'https://pricingappbackend.homemadefoodshop.com';

const ItemSub_categoriesPage = () => {
  const [categories, setCategories] = useState([]);
  const [sub_categories, setSub_categories] = useState([]);
  const [newSub_category, setNewSub_category] = useState({
    itemsub_categoryid: null, // Updated to match the new naming
    itemcategoryid: '',
    itemsub_categoryname: '',
    itemsub_categorydesc: '',
    itemcategoryname: '',
  });
  const [open, setOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/Itemcategory/getall`);
        setCategories(response.data.data || []);
      } catch (error) {
        setErrorMessage('Error fetching categories');
        console.error('Error fetching categories:', error);
      }
    };

    const fetchSub_categories = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/itemsubcategory/allitemsubcategory`);
        setSub_categories(response.data.data || []);
      } catch (error) {
        setErrorMessage('Error fetching subcategories');
        console.error('Error fetching subcategories:', error);
      }
    };

    fetchCategories();
    fetchSub_categories();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewSub_category({ ...newSub_category, [name]: value });
  };

  const handleSubmit = async () => {
    try {
      const selectedCategory = categories.find(category => category.itemcategoryid === newSub_category.itemcategoryid);
      
      // Prepare the payload according to the new structure
      const payload = {
        itemsub_categoryid: newSub_category.itemsub_categoryid || null, // Set to null if adding new
        itemsub_categoryname: newSub_category.itemsub_categoryname,
        itemsub_categorydesc: newSub_category.itemsub_categorydesc,
        itemcategoryid: newSub_category.itemcategoryid,
        itemcategoryname: selectedCategory ? selectedCategory.itemcategoryname : null, // Get category name if exists
      };

      if (isEditing) {
        await axios.post(`${BASE_URL}/itemsubcategory/modifyitemsubcategory`, payload);
      } else {
        await axios.post(`${BASE_URL}/itemsubcategory/additemsubcategory`, payload);
      }

      const response = await axios.get(`${BASE_URL}/itemsubcategory/allitemsubcategory`);
      setSub_categories(response.data.data || []);
      
      // Reset state fields after submit
      setNewSub_category({
        itemsub_categoryid: null, // Reset ID for new entries
        itemcategoryid: '',
        itemsub_categoryname: '',
        itemsub_categorydesc: '',
        itemcategoryname: '',
      });
      
      setOpen(false);
      setSuccessMessage(isEditing ? 'Subcategory updated successfully' : 'Subcategory added successfully');
      setIsEditing(false);
    } catch (error) {
      setErrorMessage(isEditing ? 'Error updating subcategory' : 'Error adding subcategory');
      console.error(isEditing ? 'Error updating subcategory:' : 'Error adding subcategory:', error);
    }
  };

  const handleEdit = (sub_category) => {
    setNewSub_category({
      itemsub_categoryid: sub_category.itemsub_categoryid, // Set ID for editing
      itemcategoryid: sub_category.itemcategoryid,
      itemsub_categoryname: sub_category.itemsub_categoryname,
      itemsub_categorydesc: sub_category.itemsub_categorydesc,
      itemcategoryname: sub_category.itemcategoryname,
    });
    setIsEditing(true);
    setOpen(true);
  };

  const handleDelete = async (itemsub_categoryid) => {
    try {
        const url = `${BASE_URL}/itemsubcategory/deleteitemsubcategory?itemsubcategoryid=${itemsub_categoryid}`;
        console.log('Deleting from URL:', url); // Log the URL
        await axios.post(url, null);
        
        const response = await axios.get(`${BASE_URL}/itemsubcategory/allitemsubcategory`);
        setSub_categories(response.data.data || []);
        setSuccessMessage('Subcategory deleted successfully');
    } catch (error) {
        setErrorMessage('Error deleting subcategory');
        console.error('Error deleting subcategory:', error);
    }
};


  

  const handleCloseSnackbar = () => {
    setSuccessMessage('');
    setErrorMessage('');
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4" gutterBottom>
        DISH SubCategories
      </Typography>

      {/* Success/Error Messages */}
      <Snackbar open={!!successMessage} autoHideDuration={4000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar open={!!errorMessage} autoHideDuration={4000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>

      {/* Search and Add New Subcategory Section */}
      <Box display="flex" alignItems="center" mb={2}>
        <TextField label="Dish Subcategory" variant="outlined" size="small" sx={{ marginRight: 1 }} />
        <Button variant="contained" startIcon={<Search />}>
          Search
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setOpen(!open);
            setIsEditing(false); // Reset editing state when opening
            setNewSub_category({
              itemsub_categoryid: null, // Reset all fields
              itemcategoryid: '',
              itemsub_categoryname: '',
              itemsub_categorydesc: '',
              itemcategoryname: '',
            });
          }}
          // startIcon={<Add />}
          // sx={{ marginLeft: 'auto' }}
        >
          Add New Subcategory
        </Button>
      </Box>

      {/* Add New Subcategory Form */}
      <Collapse in={open}>
        <Box mb={2}>
          <Typography variant="h6" gutterBottom>
            {isEditing ? 'Edit Subcategory' : 'Add New Subcategory'}
          </Typography>
          <FormControl variant="outlined" size="small" fullWidth sx={{ marginBottom: 1 }}>
            <InputLabel>Category</InputLabel>
            <Select
              value={newSub_category.itemcategoryid}
              onChange={handleChange}
              label="Category"
              name="itemcategoryid"
            >
              {categories.length > 0
                ? categories.map((category) => (
                    <MenuItem key={category.itemcategoryid} value={category.itemcategoryid}>
                      {category.itemcategoryname}
                    </MenuItem>
                  ))
                : (
                  <MenuItem disabled>No Categories Available</MenuItem>
                )}
            </Select>
          </FormControl>
          <TextField
            label="Subcategory Name"
            name="itemsub_categoryname"
            value={newSub_category.itemsub_categoryname}
            onChange={handleChange}
            variant="outlined"
            size="small"
            fullWidth
            sx={{ marginBottom: 1 }}
          />
          <TextField
            label="Description"
            name="itemsub_categorydesc"
            value={newSub_category.itemsub_categorydesc}
            onChange={handleChange}
            variant="outlined"
            size="small"
            fullWidth
            sx={{ marginBottom: 1 }}
          />
          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{ display: 'block', marginRight: 'auto' }}
          >
            {isEditing ? 'Update' : 'Save'}
          </Button>
        </Box>
      </Collapse>

      {/* Display Subcategories in a Table */}
      <TableContainer component={Paper} sx={{ marginTop: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Subcategory Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Categories</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sub_categories.length > 0
              ? sub_categories.map((sub_category) => (
                  <TableRow key={sub_category.itemsub_categoryid}>
                    <TableCell>{sub_category.itemsub_categoryname}</TableCell>
                    <TableCell>{sub_category.itemsub_categorydesc}</TableCell>
                    <TableCell>{sub_category.itemcategoryname}</TableCell>
                    <TableCell>
                      <IconButton
                        size="small"
                        color="primary"
                        onClick={() => handleEdit(sub_category)}
                      >
                        <Edit fontSize="inherit" />
                      </IconButton>
                      <IconButton size="small" color="secondary" onClick={() => handleDelete(sub_category.itemsub_categoryid)}>
    <Delete fontSize="inherit" />
</IconButton>

                    </TableCell>
                  </TableRow>
                ))
              : (
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    No Subcategories Available
                  </TableCell>
                </TableRow>
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ItemSub_categoriesPage;

import React from 'react';
import { AppBar, Toolbar, Typography, Button, Box } from '@mui/material';


const Navbar = () => {
  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          <img src="/logo.png" alt="Logo" style={{ height: '40px' }} />
        </Typography>
        <Typography>
            HOMETHALI
        </Typography>
        {/* <Box sx={{ flexGrow: 1 }}>
          <Button color="inherit">Home</Button>
          <Button color="inherit">About</Button>
          <Button color="inherit">Contact</Button>
        </Box> */}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Typography,
  TextField,
  Button,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  IconButton,
  Collapse,
} from '@mui/material';
import { Search, Edit, Add, Delete } from '@mui/icons-material';

const BASE_URL = 'https://pricingappbackend.homemadefoodshop.com/Itemcategory';

const ItemCategoriesPage = () => {
  const [categories, setCategories] = useState([]);
  const [open, setOpen] = useState(false);
  const [newCategory, setNewCategory] = useState({ itemcategoryname: '', itemcategorydesc: '' }); 
  const [editCategory, setEditCategory] = useState(null);

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/getall`);
      console.log('Fetched Categories:', response.data.data);
      setCategories(response.data.data || []);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewCategory({
      ...newCategory,
      [name]: value
    });
  };

  const handleSubmit = async () => {
    try {
      console.log('Submitting New Category:', newCategory); // Log before submitting
      const response = await axios.post(`${BASE_URL}/additemcategory`, newCategory);
      if (response.data.success) {
        fetchCategories();
        setNewCategory({ itemcategoryname: '', itemcategorydesc: '' });
        setOpen(false);
      }
    } catch (error) {
      console.error('Error adding category:', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(`${BASE_URL}/deleteitemcategory`, {
        params: { itemcategoryid: id },
      });
      if (response.data.success) {
        fetchCategories();
      }
    } catch (error) {
      console.error('Error deleting category:', error);
    }
  };

  const handleEdit = (category) => {
    setEditCategory(category);
    setNewCategory({
      itemcategoryname: category.itemcategoryname,
      itemcategorydesc: category.itemcategorydesc,
    });
    setOpen(true);
  };

  const handleUpdate = async () => {
    try {
      console.log('Updating Category:', { itemcategoryid: editCategory.itemcategoryid, ...newCategory }); // Log before updating
      const response = await axios.post(`${BASE_URL}/modifyitemcategory`, {
        itemcategoryid: editCategory.itemcategoryid,
        ...newCategory,
      });
      if (response.data.success) {
        fetchCategories();
        setNewCategory({ itemcategoryname: '', itemcategorydesc: '' });
        setOpen(false);
        setEditCategory(null);
      }
    } catch (error) {
      console.error('Error updating category:', error);
    }
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4" gutterBottom>Dish Categories</Typography>
      
      <Box display="flex" alignItems="center" mb={2}>
        <TextField
          label="Search Dish Category"
          variant="outlined"
          size="small"
          sx={{ marginRight: 1 }}
        />
        <Button
          variant="contained"
          onClick={() => {}}
          startIcon={<Search />}
        >
          Search
        </Button>
        <Button
          variant="contained"
          onClick={() => setOpen(!open)}
          startIcon={<Add />}
          sx={{ marginLeft: 'auto' }}
        >
          Add New Category
        </Button>
      </Box>

      <Collapse in={open}>
        <Box mb={2}>
          <Typography variant="h6" gutterBottom>Add New Category</Typography>
          <TextField
            label="Category Name"
            name="itemcategoryname"
            value={newCategory.itemcategoryname}
            onChange={handleChange}
            variant="outlined"
            size="small"
            fullWidth
            sx={{ marginBottom: 1 }}
          />
          <TextField
            label="Description"
            name="itemcategorydesc"
            value={newCategory.itemcategorydesc}
            onChange={handleChange}
            variant="outlined"
            size="small"
            fullWidth
            sx={{ marginBottom: 1 }}
          />
          <Button
            variant="contained"
            onClick={editCategory ? handleUpdate : handleSubmit}
            sx={{ display: 'block', marginRight: 'auto' }}
          >
            {editCategory ? 'Update' : 'Save'}
          </Button>
        </Box>
      </Collapse>

      <TableContainer component={Paper} sx={{ marginTop: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Category Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {categories.length > 0 ? (
              categories.map((category) => (
                <TableRow key={category.itemcategoryid}>
                  <TableCell>{category.itemcategoryname}</TableCell>
                  <TableCell>{category.itemcategorydesc}</TableCell>
                  <TableCell>
                    <IconButton size="small" onClick={() => handleEdit(category)} color="primary">
                      <Edit fontSize="inherit" />
                    </IconButton>
                    <IconButton size="small" onClick={() => handleDelete(category.itemcategoryid)} color="secondary">
                      <Delete fontSize="inherit" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={3}>No categories available</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ItemCategoriesPage;

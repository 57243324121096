import React, { useState } from 'react';
import {
  List, ListItem, ListItemText, Drawer, Collapse,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

const Sidebar = () => {
  const [openIngredients, setOpenIngredients] = useState(false);
  const [openItem, setOpenItem] = useState(false);
  const [openMasterDish, setOpenMasterDish] = useState(false);

  const handleClickIngredients = () => {
    setOpenIngredients(!openIngredients);
  };

  const handleClickItem = () => {
    setOpenItem(!openItem);
  };

  const handleClickMasterDish = () => {
    setOpenMasterDish(!openMasterDish);
  };

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: 240,
        flexShrink: 0,
        '& .MuiDrawer-paper': { width: 240, boxSizing: 'border-box', position: 'relative' },
      }}
    >
      <List>
        {/* Ingredients Section */}
        <ListItem button onClick={handleClickIngredients}>
          <ListItemText primary="Ingredients" />
          {openIngredients ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openIngredients} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button component={Link} to="/ingredient-categories" sx={{ pl: 4 }}>
              <ListItemText primary="Categories" />
            </ListItem>
            <ListItem button component={Link} to="/ingredient-subcategories" sx={{ pl: 4 }}>
              <ListItemText primary="Subcategories" />
            </ListItem>
            <ListItem button component={Link} to="/ingredients" sx={{ pl: 4 }}>
              <ListItemText primary="Ingredients" />
            </ListItem>
            <ListItem button component={Link} to="/ingredients-price" sx={{ pl: 4 }}>
              <ListItemText primary="Ingredients Price" />
            </ListItem>
          </List>
        </Collapse>

        {/* Item Section */}
        <ListItem button onClick={handleClickItem}>
          <ListItemText primary="Dishes" />
          {openItem ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openItem} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button component={Link} to="/item-categories" sx={{ pl: 4 }}>
              <ListItemText primary="Categories" />
            </ListItem>
            <ListItem button component={Link} to="/item-subcategories" sx={{ pl: 4 }}>
              <ListItemText primary="Subcategories" />
            </ListItem>
            <ListItem button component={Link} to="/items" sx={{ pl: 4 }}>
              <ListItemText primary="Dishes" />
            </ListItem>
          </List>
        </Collapse>

        {/* Analyst Section */}
        <ListItem button component={Link} to="/analyst">
          <ListItemText primary="Analyst" />
        </ListItem>

        {/* Master Dish Recipes Section */}
        <ListItem button onClick={handleClickMasterDish}>
          <ListItemText primary="Dish Analysis" />
          {openMasterDish ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openMasterDish} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button component={Link} to="/recipe-details" sx={{ pl: 4 }}>
              <ListItemText primary="Recipe Details" />
            </ListItem>
            <ListItem button component={Link} to="/price-analysis" sx={{ pl: 4 }}>
              <ListItemText primary="Price Analysis" />
            </ListItem>
          </List>
        </Collapse>
      </List>
    </Drawer>
  );
};

export default Sidebar;

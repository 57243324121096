import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  IconButton,
  Collapse,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from '@mui/material';
import { Search, Edit, Add, Delete } from '@mui/icons-material';
import axios from 'axios';

const BASE_URL = 'https://pricingappbackend.homemadefoodshop.com'; // Define the base URL here

const ItemsPage = () => {
  const [categories, setCategories] = useState([]);
  const [sub_categories, setSub_categories] = useState([]);
  const [items, setItems] = useState([]);
  const [open, setOpen] = useState(false);
  const [newItems, setNewItems] = useState([{
    item_name: '',
    item_description: '',
    categoryId: '',
    subcategoryId: '',
    item_price: '',
    item_quantity: '',
    category: '',  // Added
    subcategory: '' // Added
  }]);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/Itemcategory/getall`);
        setCategories(response.data.data || []);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    const fetchSub_categories = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/itemsubcategory/allitemsubcategory`);
        setSub_categories(response.data.data || []);
      } catch (error) {
        console.error('Error fetching subcategories:', error);
      }
    };

    const fetchItems = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/item/allItems`);
        setItems(response.data.data || []);
      } catch (error) {
        console.error('Error fetching items:', error);
      }
    };

    fetchCategories();
    fetchSub_categories();
    fetchItems();
  }, []);

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const updatedItems = [...newItems];
    updatedItems[index] = { ...updatedItems[index], [name]: value };

    // Update category and subcategory based on selections
    if (name === 'categoryId') {
      const selectedCategory = categories.find(cat => cat.itemcategoryid === value);
      updatedItems[index].category = selectedCategory ? selectedCategory.itemcategoryname : '';
    } else if (name === 'subcategoryId') {
      const selectedSubCategory = sub_categories.find(subcat => subcat.itemsub_categoryid === value);
      updatedItems[index].subcategory = selectedSubCategory ? selectedSubCategory.itemsub_categoryname : '';
    }

    setNewItems(updatedItems);
  };

  const addNewRow = () => {
    setNewItems([...newItems, {
      item_name: '',
      item_description: '',
      categoryId: '',
      subcategoryId: '',
      item_price: '',
      item_quantity: '',
      category: '',  // Added
      subcategory: '' // Added
    }]);
  };

  const handleSubmit = async () => {
    try {
      for (const item of newItems) {
        await axios.post(`${BASE_URL}/item/addItem`, {
          item_name: item.item_name,
          item_description: item.item_description,
          categoryId: item.categoryId,
          subcategoryId: item.subcategoryId,
          item_price: item.item_price,
          item_quantity: item.item_quantity,
          category: item.category,      // Include this
          subcategory: item.subcategory, // Include this
        });
      }
      const response = await axios.get(`${BASE_URL}/item/allItems`);
      setItems(response.data.data || []);
      setNewItems([{
        item_name: '',
        item_description: '',
        categoryId: '',
        subcategoryId: '',
        item_price: '',
        item_quantity: '',
        category: '',  // Reset
        subcategory: '' // Reset
      }]); // Reset form
      setOpen(false);
    } catch (error) {
      console.error('Error adding items:', error);
    }
  };

  const handleSearch = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/item/getItem?itemNameOrId=${searchQuery}`);
      setItems(response.data.data || []);
    } catch (error) {
      console.error('Error searching items:', error);
    }
  };

  const handleModify = async (item) => {
    try {
      await axios.get(`${BASE_URL}/item/modifyItem`, item);
      const response = await axios.get(`${BASE_URL}/item/allItems`);
      setItems(response.data.data || []);
    } catch (error) {
      console.error('Error modifying item:', error);
    }
  };

  const handleDelete = async (itemId) => {
    try {
      await axios.post(`${BASE_URL}/item/deleteItem`, null, {
        params: { itemId }
      });
      const response = await axios.get(`${BASE_URL}/item/allItems`);
      setItems(response.data.data || []);
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4" gutterBottom>Dishes</Typography>
      
      {/* Search and Add New Item Section */}
      <Box display="flex" alignItems="center" mb={2}>
        <TextField
          label="Dish Name or ID"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{ marginRight: 1 }}
        />
        <Button
          variant="contained"
          onClick={handleSearch}
          startIcon={<Search />}
        >
          Search
        </Button>
      </Box>

      {/* Add New Item Form */}
      <Collapse in={open}>
        <Box mb={2}>
          <Typography variant="h6" gutterBottom>Add Multiple Items</Typography>
          {newItems.map((newItem, index) => (
            <Box key={index} mb={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Dish Name"
                    name="item_name"
                    value={newItem.item_name}
                    onChange={(e) => handleChange(e, index)}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Category</InputLabel>
                    <Select
                      name="categoryId"
                      value={newItem.categoryId}
                      onChange={(e) => handleChange(e, index)}
                      label="Category"
                    >
                      {categories.map((category) => (
                        <MenuItem key={category.itemcategoryid} value={category.itemcategoryid}>
                          {category.itemcategoryname}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Dish Description"
                    name="item_description"
                    value={newItem.item_description}
                    onChange={(e) => handleChange(e, index)}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Subcategory</InputLabel>
                    <Select
                      name="subcategoryId"
                      value={newItem.subcategoryId}
                      onChange={(e) => handleChange(e, index)}
                      label="Subcategory"
                    >
                      {sub_categories.map((sub_category) => (
                        <MenuItem key={sub_category.itemsub_categoryid} value={sub_category.itemsub_categoryid}>
                          {sub_category.itemsub_categoryname}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Dish Price"
                    name="item_price"
                    value={newItem.item_price}
                    onChange={(e) => handleChange(e, index)}
                    fullWidth
                    variant="outlined"
                    type="number"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Dish Quantity"
                    name="item_quantity"
                    value={newItem.item_quantity}
                    onChange={(e) => handleChange(e, index)}
                    fullWidth
                    variant="outlined"
                    type="number"
                  />
                </Grid>
              </Grid>
            </Box>
          ))}
          <Button onClick={addNewRow} startIcon={<Add />}>
            Add Another Item
          </Button>
        </Box>
      </Collapse>
      <Button onClick={() => setOpen(!open)} startIcon={<Add />} variant="contained" color="primary">
        {open ? 'Cancel' : 'Add New Dish'}
      </Button>

      {/* Submit Button */}
      {open && (
        <Box mt={2}>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Box>
      )}

      {/* Items Table */}
      <TableContainer component={Paper} sx={{ marginTop: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Dish ID</TableCell>
              <TableCell>Dish Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Subcategory</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => {
              const category = categories.find(cat => cat.itemcategoryid === item.categoryId);
              const subcategory = sub_categories.find(subcat => subcat.itemsub_categoryid === item.subcategoryId);
              return (
                <TableRow key={item.item_id}>
                  <TableCell>{item.item_id}</TableCell>
                  <TableCell>{item.item_name}</TableCell>
                  <TableCell>{item.item_description}</TableCell>
                  <TableCell>{item.category}</TableCell>
                  <TableCell>{item.sub_category}</TableCell>
                  <TableCell>{item.item_price}</TableCell>
                  <TableCell>{item.item_quantity}</TableCell>
                  <TableCell>
                    <IconButton color="primary" onClick={() => handleModify(item)}>
                      <Edit />
                    </IconButton>
                    <IconButton color="secondary" onClick={() => handleDelete(item.item_id)}>
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ItemsPage;

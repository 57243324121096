import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  TextField,
  Button,
  Container,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import Navbar from './Navbar';


const MLIPage = () => {
  const navigate = useNavigate();

  const [categories] = useState(['Vegetables', 'Spices', 'Fruits', 'Dairy', 'Meat']);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [ingredients, setIngredients] = useState({
    Vegetables: ['Carrot', 'Potato', 'Tomato'],
    Spices: ['Pepper', 'Cumin', 'Turmeric'],
    Fruits: ['Apple', 'Banana', 'Orange'],
    Dairy: ['Milk', 'Cheese', 'Yogurt'],
    Meat: ['Chicken', 'Beef', 'Pork'],
  });
  const [newIngredient, setNewIngredient] = useState('');

  const handleAddIngredient = () => {
    if (newIngredient.trim() && selectedCategory) {
      setIngredients({
        ...ingredients,
        [selectedCategory]: [...ingredients[selectedCategory], newIngredient.trim()],
      });
      setNewIngredient('');
    }
  };

  const handleSelectIngredient = (ingredient) => {
    navigate('/main');
  };

  return (
    <>
      <Navbar />
      <Container maxWidth="sm" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
        <Paper elevation={3} sx={{ padding: 4, width: '100%' }}>
          <Typography variant="h4" component="h1" gutterBottom align="center">
            Master List of Ingredients
          </Typography>
          <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
            <InputLabel id="category-label">Category</InputLabel>
            <Select
              labelId="category-label"
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
              label="Category"
            >
              {categories.map((category, index) => (
                <MenuItem key={index} value={category}>
                  {category}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {selectedCategory && (
            <>
              <List>
                {ingredients[selectedCategory].map((ingredient, index) => (
                  <ListItem button key={index} onClick={() => handleSelectIngredient(ingredient)}>
                    <ListItemText primary={ingredient} />
                  </ListItem>
                ))}
              </List>
              <Box mt={2} mb={2}>
                <TextField
                  label="Add new ingredient"
                  variant="outlined"
                  fullWidth
                  value={newIngredient}
                  onChange={(e) => setNewIngredient(e.target.value)}
                />
              </Box>
              <Button variant="contained" color="primary" onClick={handleAddIngredient} fullWidth>
                Add Ingredient
              </Button>
            </>
          )}
        </Paper>
      </Container>
    </>
  );
};

export default MLIPage;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import './Signin.css';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Divider from '@mui/material/Divider';
import AccountCircle from '@mui/icons-material/AccountCircle';
import GoogleIcon from '@mui/icons-material/Google';
import axios from 'axios';

const Signin = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const emailRegex = /^[a-zA-Z0-9._-]+@gmail\.com$/;

    // Check for valid email format
    if (!emailRegex.test(username)) {
        setError('Username must be a valid Gmail address');
        return;
    }
    
    // Check for password length
    if (password.length < 9) {
        setError('Password must be at least 9 characters long');
        return;
    }

    // Dummy data for testing
    const dummyUsername = 'test@gmail.com';
    const dummyPassword = 'password123';

    // If username and password match dummy data, navigate to /main
    if (username === dummyUsername && password === dummyPassword) {
        setError('');
        console.log("Dummy login successful");
        navigate('/main');
        return;
    }

    // Clear any previous error
    setError('');

    // Actual API call logic
    try {
        const response = await axios.post('/user/loginUser', {
            email: username,
            password,
        });

        console.log(response.data);
        // Handle successful sign-in (e.g., redirect to main page)
        navigate('/main');
    } catch (err) {
        setError(err.response.data || 'An error occurred during sign-in');
        console.error(err);
    }
};



  const handleNavigateToRegister = () => {
    navigate('/register');
  };

  return (
    <div className="container">
      <nav className="navbar">
        <div className="logo">HomeThali</div>
      </nav>
      <div className="form-container">
        <h2 className="heading">Sign In</h2>
        {error && <div className="error">{error}</div>}
        <form onSubmit={handleSubmit}>
          <TextField
            id="input-with-icon-textfield"
            label="Username"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
            variant="standard"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            fullWidth
            margin="normal"
          />
          <FormControl fullWidth variant="standard" margin="normal">
            <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
            <Input
              id="standard-adornment-password"
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <a href="#" className="forgot-password">Forgot Password?</a>
          </FormControl>
          <Button variant="contained" type="submit" fullWidth sx={{ mt: 2 }}>
            Sign In
          </Button>
          <p className="register-link">
            Not a user?{' '}
            <a href="#" onClick={handleNavigateToRegister}>
              Register now
            </a>
          </p>
          <Divider sx={{ mt: 3 }}>Continue with</Divider>
          <Button
            variant="outlined"
            startIcon={<GoogleIcon />}
            fullWidth
            sx={{ mt: 2 }}
          >
            Google
          </Button>
        </form>
      </div>
    </div>
  );
};

export default Signin;

import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Box,
  Select,
  MenuItem,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Snackbar,
  Alert
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';

const API_BASE_URL = 'https://pricingappbackend.homemadefoodshop.com/recipedetails'; // Replace with your API endpoint

const RecipeDetails = () => {
  const [dishName, setDishName] = useState('');
  const [ingredients, setIngredients] = useState([]);
  const [newIngredients, setNewIngredients] = useState([{
    ingredientCategory: '', sub_Category: '', ingredient: '', quantity: '', measure: '', analyst: '', systemPrice: '', unit: 'g', Minprice: '', Maxprice: ''
  }]);
  const [ingredientCategories, setIngredientCategories] = useState([]);
  const [sub_Categories, setSub_Categories] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [editIngredient, setEditIngredient] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  useEffect(() => {
    fetchIngredients();
    fetchCategories();
    fetchSub_categories();
  }, []);

  const fetchIngredients = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/getRecipeDetails`);
      console.log('Ingredients Response:', response.data);
      
      // Ensure the response is an array and map the data correctly
      setIngredients(Array.isArray(response.data) ? response.data.map(item => ({
        id: item.id, // Assuming the backend returns an id for each ingredient
        ingredientCategory: item.ingredient_category,
        sub_Category: item.sub_category,
        ingredient: item.ingredient,
        quantity: item.quantity,
        measure: item.measure,
        systemPrice: item.system_price,
        Minprice: item.min_price,
        Maxprice: item.max_price,
        analyst: item.analyst
      })) : []);
    } catch (error) {
      console.error('Error fetching ingredients:', error);
    }
  };
  
  

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`https://pricingappbackend.homemadefoodshop.com/Ingcategory/getallcategories`);
      // Ensure the response is an array
      setIngredientCategories(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error('Error fetching categories:', error);
      // Set an empty array if there's an error
      setIngredientCategories([]);
    }
  };
  

  const fetchSub_categories = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/getAllSubCategories`);
      setSub_Categories(response.data || {});
    } catch (error) {
      console.error('Error fetching subcategories:', error);
    }
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedIngredients = [...newIngredients];
    updatedIngredients[index] = { ...updatedIngredients[index], [name]: value };
    setNewIngredients(updatedIngredients);
  };

  const handleCategoryChange = (e, index) => {
    const selectedCategory = e.target.value;
    setNewIngredients(prevIngredients => {
      const updatedIngredients = [...prevIngredients];
      updatedIngredients[index] = {
        ...updatedIngredients[index],
        ingredientCategory: selectedCategory,
        sub_Category: '' // Reset subCategory when category changes
      };
      return updatedIngredients;
    });
  };

  const handleSub_CategoryChange = (e, index) => {
    setNewIngredients(prevIngredients => {
      const updatedIngredients = [...prevIngredients];
      updatedIngredients[index] = { ...updatedIngredients[index], sub_Category: e.target.value };
      return updatedIngredients;
    });
  };

  const handleAddIngredientRow = () => {
    setNewIngredients(prevIngredients => [
      ...prevIngredients,
      { ingredientCategory: '', sub_Category: '', ingredient: '', quantity: '', measure: '', analyst: '', systemPrice: '', unit: 'g', Minprice: '', Maxprice: '' }
    ]);
  };

  const handleAddRecipes = async () => {
    try {
      await axios.post(`${API_BASE_URL}/addRecipeDetails`, newIngredients.map(item => ({
        ingredientName: item.ingredient,
        quantity: `${item.quantity} ${item.unit}`,
        measure: item.measure,
        price: item.systemPrice,
        analyst: item.analyst,
        Minprice: item.Minprice,
        Maxprice: item.Maxprice
      })));
      setSnackbarMessage('Ingredients added successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      setDishName('');
      setNewIngredients([{ ingredientCategory: '', sub_Category: '', ingredient: '', quantity: '', measure: '', analyst: '', systemPrice: '', unit: 'g', Minprice: '', Maxprice: '' }]);
      setOpenDialog(false);
      fetchIngredients();
    } catch (error) {
      console.error('Error adding ingredients:', error);
      setSnackbarMessage('Error adding ingredients');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleEditIngredientChange = (e) => {
    const { name, value } = e.target;
    setEditIngredient(prev => ({ ...prev, [name]: value }));
  };

  const handleEditIngredientSave = async () => {
    try {
      await axios.put(`${API_BASE_URL}/updateIngredient/${editIngredient.id}`, {
        ingredientName: editIngredient.ingredient,
        quantity: `${editIngredient.quantity} ${editIngredient.unit}`,
        measure: editIngredient.measure,
        price: editIngredient.systemPrice,
        analyst: editIngredient.analyst,
        Minprice: editIngredient.Minprice,
        Maxprice: editIngredient.Maxprice
      });
      setSnackbarMessage('Ingredient updated successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      setOpenEditDialog(false);
      fetchIngredients();
    } catch (error) {
      console.error('Error updating ingredient:', error);
      setSnackbarMessage('Error updating ingredient');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleEditIngredient = (ingredient) => {
    setEditIngredient(ingredient);
    setOpenEditDialog(true);
  };

  const handleDeleteIngredient = async (ingredientId) => {
    try {
      await axios.delete(`${API_BASE_URL}/deleteIngredient/${ingredientId}`);
      setSnackbarMessage('Ingredient deleted successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      fetchIngredients();
    } catch (error) {
      console.error('Error deleting ingredient:', error);
      setSnackbarMessage('Error deleting ingredient');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);
  const handleCloseEditDialog = () => setOpenEditDialog(false);

  return (
    <Container>
      <Typography variant="h4" gutterBottom>Recipe Details</Typography>

      {/* Dish Name */}
      <TextField
        label="Dish Name"
        value={dishName}
        onChange={(e) => setDishName(e.target.value)}
        fullWidth
        margin="normal"
      />

      {/* Ingredients Table */}
      <Box mt={2} mb={2}>
        <Table component={Paper}>
          <TableHead>
            <TableRow>
              <TableCell>Category</TableCell>
              <TableCell>SubCategory</TableCell>
              <TableCell>Ingredient</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Measure</TableCell>
              <TableCell>HT -Price</TableCell>
              <TableCell>Minprice</TableCell>
              <TableCell>Maxprice</TableCell>
              <TableCell>Analyst</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
  {ingredients.map((ingredient) => (
    <TableRow key={ingredient.id}>
      <TableCell>{ingredient.ingredientCategory}</TableCell>
      <TableCell>{ingredient.sub_Category}</TableCell>
      <TableCell>{ingredient.ingredient}</TableCell>
      <TableCell>{ingredient.quantity}</TableCell>
      <TableCell>{ingredient.measure}</TableCell>
      <TableCell>{ingredient.systemPrice}</TableCell>
      <TableCell>{ingredient.Minprice}</TableCell>
      <TableCell>{ingredient.Maxprice}</TableCell>
      <TableCell>{ingredient.analyst}</TableCell>
      <TableCell>
        <IconButton onClick={() => handleEditIngredient(ingredient)}>
          <EditIcon />
        </IconButton>
        <IconButton onClick={() => handleDeleteIngredient(ingredient.id)}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  ))}
</TableBody>
        </Table>
      </Box>

      {/* Button to open dialog */}
      <Button
        variant="outlined"
        color="primary"
        onClick={handleOpenDialog}
      >
        Add Ingredients
      </Button>

      {/* Dialog for adding ingredients */}
      <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth maxWidth="lg">
        <DialogTitle>Add Ingredients</DialogTitle>
        <DialogContent>
          {newIngredients.map((ingredient, index) => (
            <Box key={index} mb={2}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={3}>
                <Select
  value={ingredient.ingredientCategory}
  onChange={(e) => handleCategoryChange(e, index)}
  displayEmpty
  fullWidth
  label="Category"
>
  <MenuItem value="" disabled>Select Category</MenuItem>
  {Array.isArray(ingredientCategories) && ingredientCategories.map((cat, i) => (
    <MenuItem key={i} value={cat}>{cat}</MenuItem>
  ))}
</Select>

                </Grid>
                <Grid item xs={12} sm={3}>
                  <Select
                    value={ingredient.sub_Category}
                    onChange={(e) => handleSub_CategoryChange(e, index)}
                    displayEmpty
                    fullWidth
                    label="Sub Category"
                  >
                    <MenuItem value="" disabled>Select Sub Category</MenuItem>
                    {(sub_Categories[ingredient.ingredientCategory] || []).map((subCat, i) => (
                      <MenuItem key={i} value={subCat}>{subCat}</MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    name="ingredient"
                    label="Ingredient"
                    value={ingredient.ingredient}
                    onChange={(e) => handleInputChange(e, index)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    name="quantity"
                    label="Quantity"
                    value={ingredient.quantity}
                    onChange={(e) => handleInputChange(e, index)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    name="measure"
                    label="Measure"
                    value={ingredient.measure}
                    onChange={(e) => handleInputChange(e, index)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    name="systemPrice"
                    label="System Price"
                    value={ingredient.systemPrice}
                    onChange={(e) => handleInputChange(e, index)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    name="Minprice"
                    label="Min Price"
                    value={ingredient.Minprice}
                    onChange={(e) => handleInputChange(e, index)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    name="Maxprice"
                    label="Max Price"
                    value={ingredient.Maxprice}
                    onChange={(e) => handleInputChange(e, index)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    name="analyst"
                    label="Analyst"
                    value={ingredient.analyst}
                    onChange={(e) => handleInputChange(e, index)}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Box>
          ))}
          <Button variant="outlined" onClick={handleAddIngredientRow}>Add More Ingredients</Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleAddRecipes}>Add</Button>
        </DialogActions>
      </Dialog>

      {/* Edit Ingredient Dialog */}
      <Dialog open={openEditDialog} onClose={handleCloseEditDialog}>
        <DialogTitle>Edit Ingredient</DialogTitle>
        <DialogContent>
          <TextField
            name="ingredient"
            label="Ingredient"
            value={editIngredient ? editIngredient.ingredient : ''}
            onChange={handleEditIngredientChange}
            fullWidth
          />
          {/* Add other fields as necessary */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditDialog}>Cancel</Button>
          <Button onClick={handleEditIngredientSave}>Save</Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for feedback */}
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default RecipeDetails;

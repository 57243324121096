import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  IconButton,
  Collapse,
  Grid,
} from '@mui/material';
import { Search, Edit, Add, Delete } from '@mui/icons-material';
import axios from 'axios';

const BASE_URL = 'https://pricingappbackend.homemadefoodshop.com/analyst'; // Base URL for API calls

const AnalystPage = () => {
  const [analysts, setAnalysts] = useState([]);
  const [newAnalyst, setNewAnalyst] = useState({ analystName: '', details: '', isActive: true, createdBy: '' });
  const [searchQuery, setSearchQuery] = useState('');
  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    const fetchAnalysts = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/getAllAnalyst`);
        setAnalysts(response.data.data || []);
      } catch (error) {
        console.error('Error fetching analysts:', error);
      }
    };
    fetchAnalysts();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewAnalyst({ ...newAnalyst, [name]: value });
  };

  const handleSubmit = async () => {
    try {
      if (editMode) {
        await axios.put(`${BASE_URL}/updateAnalystDetails`, newAnalyst);
      } else {
        await axios.post(`${BASE_URL}/addAnalyst`, newAnalyst);
      }
      const response = await axios.get(`${BASE_URL}/getAllAnalyst`);
      setAnalysts(response.data.data || []);
      setNewAnalyst({ analystName: '', details: '', isActive: true, createdBy: '' });
      setOpen(false);
      setEditMode(false);
    } catch (error) {
      console.error('Error saving analyst:', error);
    }
  };

  const handleSearch = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/getDetailsByAnalystName/${searchQuery}`);
      setAnalysts(response.data.data || []);
    } catch (error) {
      console.error('Error searching analyst:', error);
    }
  };

  const handleEdit = (analyst) => {
    setNewAnalyst(analyst);
    setOpen(true);
    setEditMode(true);
  };

  const handleDelete = async (analystId) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this analyst?");
    if (confirmDelete) {
      try {
        const response = await axios.delete(`${BASE_URL}/deleteAnalystById/${analystId}`);
        if (response.status === 200) {
          setAnalysts((prevAnalysts) => prevAnalysts.filter(analyst => analyst.analystId !== analystId));
        }
      } catch (error) {
        console.error('Error deleting analyst:', error);
      }
    }
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4" gutterBottom>Analyst Management</Typography>

      <Box display="flex" alignItems="center" mb={2}>
        <TextField
          label="Analyst Name"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{ marginRight: 1 }}
        />
        <Button
          variant="contained"
          onClick={handleSearch}
          startIcon={<Search />}
        >
          Search
        </Button>
        <Button
          variant="contained"
          onClick={() => setOpen(!open)}
          startIcon={<Add />}
          sx={{ marginLeft: 'auto' }}
        >
          {editMode ? 'Edit Analyst' : 'Add New Analyst'}
        </Button>
      </Box>

      <Collapse in={open}>
        <Box mb={2}>
          <Typography variant="h6" gutterBottom>{editMode ? 'Edit Analyst' : 'Add New Analyst'}</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Analyst Name"
                name="analystName"
                value={newAnalyst.analystName}
                onChange={handleChange}
                variant="outlined"
                size="small"
                fullWidth
                sx={{ marginBottom: 1 }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Details"
                name="details"
                value={newAnalyst.details}
                onChange={handleChange}
                variant="outlined"
                size="small"
                fullWidth
                sx={{ marginBottom: 1 }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Created By"
                name="createdBy"
                value={newAnalyst.createdBy}
                onChange={handleChange}
                variant="outlined"
                size="small"
                fullWidth
                sx={{ marginBottom: 1 }}
              />
            </Grid>
          </Grid>
          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{ display: 'block', mt: 2 }}
          >
            Save
          </Button>
        </Box>
      </Collapse>

      <TableContainer component={Paper} sx={{ marginTop: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Analyst Name</TableCell>
              <TableCell>Details</TableCell>
              <TableCell>Created By</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {analysts.map((analyst) => (
              <TableRow key={analyst.analystId}>
                <TableCell>{analyst.analystName}</TableCell>
                <TableCell>{analyst.details}</TableCell>
                <TableCell>{analyst.createdBy}</TableCell>
                <TableCell>
                  <IconButton size="small" onClick={() => handleEdit(analyst)}>
                    <Edit fontSize="inherit" />
                  </IconButton>
                  <IconButton size="small" onClick={() => handleDelete(analyst.analystId)}>
                    <Delete fontSize="inherit" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default AnalystPage;

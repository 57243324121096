import React, { useState, useEffect } from 'react';
import {
  TextField, Button, Table, TableBody, TableCell, TableHead, TableRow,
  Paper, Container, Typography, Box, MenuItem, Select, InputLabel,
  FormControl, CircularProgress, IconButton,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';

const BASE_URL = 'https://pricingappbackend.homemadefoodshop.com';
const unitsOfMeasurement = ['MILLI_GRAMS', 'GRAMS', 'LITERS', 'KG'];

const IngredientPrices = () => {
  const [ingredients, setIngredients] = useState([]);
  const [categories, setCategories] = useState([]);
  const [sub_categories, setSub_categories] = useState([]);
  const [newIngredient, setNewIngredient] = useState({
    ingredient: '',
    description: '',
    category: '',
    sub_category: '',
    quantity: '',
    measure: '',
    price: ''
  });
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [loadingSub_categories, setLoadingSub_categories] = useState(false);
  const [loadingIngredients, setLoadingIngredients] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [editIngredientId, setEditIngredientId] = useState(null);

  useEffect(() => {
    fetchIngredients();
    fetchCategories();
  }, []);

  useEffect(() => {
    if (newIngredient.category) {
      fetchSub_categories(newIngredient.category);
    } else {
      setSub_categories([]);
      setNewIngredient({ ...newIngredient, sub_category: '' });
    }
  }, [newIngredient.category]);

  const fetchIngredients = () => {
    setLoadingIngredients(true);
    axios.get(`${BASE_URL}/ingredientPrices/allingredientPriceData`)
      .then(response => {
        setIngredients(response.data.data);
        setLoadingIngredients(false);
      })
      .catch(error => {
        setError('Failed to load ingredients.');
        setLoadingIngredients(false);
      });
  };

  const fetchCategories = () => {
    setLoadingCategories(true);
    axios.get(`${BASE_URL}/Ingcategory/getallcategories`)
      .then(response => {
        setCategories(response.data.data);
        setLoadingCategories(false);
      })
      .catch(error => {
        setError('Failed to load categories.');
        setLoadingCategories(false);
      });
  };

  const fetchSub_categories = (categoryId) => {
    setLoadingSub_categories(true);
    axios.get(`${BASE_URL}/ingscategory/getall`, { params: { categoryId } })
      .then(response => {
        setSub_categories(response.data.data);
        setLoadingSub_categories(false);
      })
      .catch(error => {
        setError('Failed to load subcategories.');
        setLoadingSub_categories(false);
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewIngredient(prevState => ({ ...prevState, [name]: value }));
  };

  const handleMeasureChange = (e) => {
    setNewIngredient(prevState => ({ ...prevState, measure: e.target.value }));
  };

  const handleAddPricing = () => {
    setError('');
    setSuccessMessage('');

    const { ingredient, quantity, measure, price, category, sub_category } = newIngredient;

    if (!ingredient || !quantity || !measure || !price || !category || !sub_category) {
      setError('Please fill in all required fields.');
      return;
    }

    setIsSubmitting(true);
    if (isEditing) {
      // Update API call
      axios.put(`${BASE_URL}/ingredientPrices/modifyingredientPrices`, {
        ...newIngredient,
        id: editIngredientId // Assuming your backend expects the ID in the request body
      })
        .then(() => {
          setSuccessMessage('Ingredient price updated successfully!');
          fetchIngredients();
          resetForm();
        })
        .catch(() => {
          setError('Failed to update ingredient price.');
        })
        .finally(() => setIsSubmitting(false));
    } else {
      // Add new ingredient API call
      axios.post(`${BASE_URL}/ingredientPrices/ingredientPriceData`, newIngredient)
        .then(() => {
          setSuccessMessage('Ingredient price added successfully!');
          fetchIngredients();
          resetForm();
        })
        .catch(() => {
          setError('Failed to add ingredient price.');
        })
        .finally(() => setIsSubmitting(false));
    }
  };

  const handleEdit = (ingredient) => {
    // Set the newIngredient state with the data of the ingredient being edited
    setNewIngredient({
      ingredient: ingredient.ingredient,
      description: ingredient.description,
      category: ingredient.category,
      sub_category: ingredient.sub_category,
      quantity: ingredient.quantity,
      measure: ingredient.measure,
      price: ingredient.price,
    });
    setEditIngredientId(ingredient.id); // Save the ID for updating
    setIsEditing(true); // Set editing mode to true
  };

  const handleDelete = (ingredientId) => {
    // Delete API call
    axios.delete(`${BASE_URL}/ingredientPrices/deleteIngredientPrices`, {
      params: { Ingredientid: ingredientId } // Correct endpoint for deletion
    })
      .then(() => {
        setSuccessMessage('Ingredient deleted successfully!');
        fetchIngredients();
      })
      .catch(() => {
        setError('Failed to delete ingredient.');
      });
  };

  const resetForm = () => {
    setNewIngredient({
      ingredient: '',
      description: '',
      category: '',
      sub_category: '',
      quantity: '',
      measure: '',
      price: ''
    });
    setIsEditing(false);
    setEditIngredientId(null);
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>Ingredient Prices</Typography>
      <Box mt={2} mb={2}>
        <Paper>
          {loadingIngredients ? (
            <Box display="flex" justifyContent="center" p={2}>
              <CircularProgress />
            </Box>
          ) : (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Ingredient</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Category</TableCell>
                  <TableCell>Subcategory</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Measure</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ingredients.length > 0 ? (
                  ingredients.map((ingredient) => (
                    <TableRow key={ingredient.id || ingredient.ingredient + ingredient.category}>
                      <TableCell>{ingredient.ingredient}</TableCell>
                      <TableCell>{ingredient.description}</TableCell>
                      <TableCell>{ingredient.category}</TableCell>
                      <TableCell>{ingredient.sub_category}</TableCell>
                      <TableCell>{ingredient.price}</TableCell>
                      <TableCell>{ingredient.quantity}</TableCell>
                      <TableCell>{ingredient.measure}</TableCell>
                      <TableCell>
                        <IconButton onClick={() => handleEdit(ingredient)} color="primary">
                          <EditIcon />
                        </IconButton>
                        <IconButton onClick={() => handleDelete(ingredient.id)} color="secondary">
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={8} align="center">
                      No ingredients available.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          )}
        </Paper>
      </Box>

      <Typography variant="h6" gutterBottom>{isEditing ? 'Edit Ingredient Pricing' : 'Add New Pricing'}</Typography>
      {error && (
        <Typography color="error" variant="body2" gutterBottom>
          {error}
        </Typography>
      )}
      {successMessage && (
        <Typography color="primary" variant="body2" gutterBottom>
          {successMessage}
        </Typography>
      )}

<FormControl variant="outlined" size="small" fullWidth sx={{ marginBottom: 1 }}>
          <InputLabel>Category</InputLabel>
          <Select
            value={newIngredient.category}
            onChange={handleInputChange}
            label="Category"
            name="category"
          >
            {categories.map((category) => (
              <MenuItem key={category.category_id} value={category.ingcategoryname}>
                {category.ingcategoryname}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl variant="outlined" size="small" fullWidth sx={{ marginBottom: 1 }}>
          <InputLabel>Subcategory</InputLabel>
          <Select
            value={newIngredient.sub_category}
            onChange={handleInputChange}
            label="Subcategory"
            name="sub_category"
          >
            {sub_categories.map((sub_category) => (
              <MenuItem key={sub_category.sub_category_id} value={sub_category.ingscategoryname}>
                {sub_category.ingscategoryname}
              </MenuItem>
            ))}
          </Select>
        </FormControl>



      <TextField
        label="Ingredient"
        variant="outlined"
        fullWidth
        margin="normal"
        name="ingredient"
        value={newIngredient.ingredient}
        onChange={handleInputChange}
      />
      <TextField
        label="Description"
        variant="outlined"
        fullWidth
        margin="normal"
        name="description"
        value={newIngredient.description}
        onChange={handleInputChange}
      />
      <TextField
        label="Quantity"
        variant="outlined"
        fullWidth
        margin="normal"
        name="quantity"
        type="number"
        value={newIngredient.quantity}
        onChange={handleInputChange}
      />
      <FormControl variant="outlined" size="small" fullWidth sx={{ marginBottom: 1 }}>
        <InputLabel>Measure</InputLabel>
        <Select
          value={newIngredient.measure}
          onChange={handleMeasureChange}
          label="Measure"
        >
          {unitsOfMeasurement.map((unit) => (
            <MenuItem key={unit} value={unit}>{unit}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        label="Price"
        variant="outlined"
        fullWidth
        margin="normal"
        name="price"
        type="number"
        value={newIngredient.price}
        onChange={handleInputChange}
      />

      <Button
        variant="contained"
        color="primary"
        onClick={handleAddPricing}
        disabled={isSubmitting}
      >
        {isEditing ? 'Update Pricing' : 'Add Pricing'}
      </Button>
      {isSubmitting && <CircularProgress size={24} />}
    </Container>
  );
};

export default IngredientPrices;

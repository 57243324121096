import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Collapse,
  Typography,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  Snackbar,
  Alert,
} from '@mui/material';
import { Search, Edit, Add, Delete } from '@mui/icons-material';

const API_BASE_URL = 'https://pricingappbackend.homemadefoodshop.com';

const Sub_Categories = () => {
  const [sub_Categories, setSub_Categories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [open, setOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentSub_Category, setCurrentSub_Category] = useState(null);
  const [newSub_Category, setNewSub_Category] = useState({
    ingscategoryid: '',
    ingscategoryname: '',
    ingscategorydesc: '',
    ingcategoryid: '',
    ingcategoryname: ''
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  useEffect(() => {
    fetchCategories();
    fetchSub_Categories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/Ingcategory/getallcategories`);
      setCategories(response.data.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const fetchSub_Categories = async () => {
    try {
      const [sub_CategoriesResponse, categoriesResponse] = await Promise.all([
        axios.get(`${API_BASE_URL}/ingscategory/getall`),
        axios.get(`${API_BASE_URL}/Ingcategory/getallcategories`)
      ]);

      const sub_CategoriesWithCategoryName = sub_CategoriesResponse.data.data.map(sub => {
        const category = categoriesResponse.data.data.find(cat => cat.ingcategoryid === sub.ingcategoryid);
        return {
          ...sub,
          ingcategoryname: category ? category.ingcategoryname : 'Unknown Category'
        };
      });

      setSub_Categories(sub_CategoriesWithCategoryName);
    } catch (error) {
      console.error('Error fetching subcategories:', error);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setNewSub_Category((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async () => {
    try {
      if (isEditing) {
        await axios.put(`${API_BASE_URL}/ingscategory/modifyingscategory`, newSub_Category);
        setSnackbarMessage('Subcategory updated successfully!');
      } else {
        await axios.post(`${API_BASE_URL}/ingscategory/addingscategory`, newSub_Category);
        setSnackbarMessage('Subcategory added successfully!');
      }
      fetchSub_Categories();
      setOpen(false);
      setNewSub_Category({
        ingscategoryid: '',
        ingscategoryname: '',
        ingscategorydesc: '',
        ingcategoryid: '',
        ingcategoryname: ''
      });
      setIsEditing(false);
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error submitting subcategory:', error);
      setSnackbarMessage('Error occurred while submitting subcategory!');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleEditClick = (sub_Category) => {
    setNewSub_Category(sub_Category);
    setIsEditing(true);
    setOpen(true);
  };

  const handleAddClick = () => {
    setNewSub_Category({
      ingscategoryid: '',
      ingscategoryname: '',
      ingscategorydesc: '',
      ingcategoryid: '',
      ingcategoryname: ''
    });
    setIsEditing(false);
    setOpen(true);
  };

  const handleDeleteClick = async (sub_Category) => {
    if (window.confirm('Are you sure you want to delete this subcategory?')) {
      try {
        await axios.delete(`${API_BASE_URL}/ingscategory/deleteingscategory/${sub_Category.ingscategoryid}`);

        fetchSub_Categories();
        setSnackbarMessage('Subcategory deleted successfully!');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      } catch (error) {
        console.error('Error deleting subcategory:', error);
        setSnackbarMessage('Error occurred while deleting subcategory!');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredSub_Categories = sub_Categories.filter((sub) =>
    sub.ingscategoryname.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4" gutterBottom>Ingredient Subcategories</Typography>
      <Box display="flex" alignItems="center" mb={2}>
        <TextField
          label="Search Subcategory"
          variant="outlined"
          size="small"
          value={searchTerm}
          onChange={handleSearchChange}
          sx={{ marginRight: 1 }}
        />
        <Button variant="contained" startIcon={<Search />}>Search</Button>
        <Button variant="contained" startIcon={<Add />} onClick={handleAddClick} sx={{ marginLeft: 1 }}>Add</Button>
      </Box>
      
      <Collapse in={open}>
        <Box mb={2}>
          <Typography variant="h6" gutterBottom>{isEditing ? 'Edit Subcategory' : 'Add New Subcategory'}</Typography>
          <TextField
            label="Subcategory Name"
            name="ingscategoryname"
            value={newSub_Category.ingscategoryname}
            onChange={handleChange}
            variant="outlined"
            size="small"
            fullWidth
            sx={{ marginBottom: 1 }}
          />
          <TextField
            label="Description"
            name="ingscategorydesc"
            value={newSub_Category.ingscategorydesc}
            onChange={handleChange}
            variant="outlined"
            size="small"
            fullWidth
            sx={{ marginBottom: 1 }}
          />
          <FormControl fullWidth variant="outlined" size="small" sx={{ marginBottom: 1 }}>
            <InputLabel>Category</InputLabel>
            <Select
              name="ingcategoryid"
              value={newSub_Category.ingcategoryid}
              onChange={handleChange}
              label="Category"
            >
              {categories.map((category) => (
                <MenuItem key={category.ingcategoryid} value={category.ingcategoryid}>
                  {category.ingcategoryname}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button variant="contained" onClick={handleSubmit}>
            {isEditing ? 'Update Subcategory' : 'Add Subcategory'}
          </Button>
        </Box>
      </Collapse>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Subcategory Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Category Name</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredSub_Categories.length > 0 ? (
              filteredSub_Categories.map((sub) => (
                <TableRow key={sub.ingscategoryid}>
                  <TableCell>{sub.ingscategoryname}</TableCell>
                  <TableCell>{sub.ingscategorydesc}</TableCell>
                  <TableCell>{sub.ingcategoryname}</TableCell>
                  <TableCell>
                    <IconButton color="primary" onClick={() => handleEditClick(sub)}>
                      <Edit />
                    </IconButton>
                    <IconButton color="error" onClick={() => handleDeleteClick(sub)}>
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4}>No subcategories available</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Snackbar for success/error messages */}
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Sub_Categories;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TextField, Button, Grid, Typography, Container, Paper, AppBar, Toolbar, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Sidebar from './Sidebar'; // Adjust the import path as needed

const IngredientsForm = ({ showSidebar, ingredientId, onEditIngredient }) => {
  const [id, setId] = useState(''); // State for ID
  const [ingredients, setIngredients] = useState('');
  const [description, setDescription] = useState('');
  const [quantity, setQuantity] = useState('');
  const [price, setPrice] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Flag for loading state

  // Fetch ingredient details if editing an existing ingredient
  useEffect(() => {
    const fetchIngredient = async () => {
      if (ingredientId) {
        setIsLoading(true);
        try {
          const response = await axios.get(`/api/ingredients/${ingredientId}`); // Replace with your API endpoint
          const data = response.data;
          setId(data.id);
          setIngredients(data.ingredients);
          setDescription(data.description);
          setQuantity(data.quantity);
          setPrice(data.price);
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchIngredient();
  }, [ingredientId]);

  const handleSave = async () => {
    console.log();
    setIsLoading(true);
    try {
      const body = {
        id, // Include ID in the body
        ingredients,
        description,
        quantity,
        price,
      };
      let url = 'https://pricingappbackend.homemadefoodshop.com/ingredients/addIngredients'; // For adding new ingredient
      let method = 'POST';
      if (ingredientId) {
        url = `https://pricingappbackend.homemadefoodshop.com/ingredients/modifyingredients`; // For modifying existing ingredient
        method = 'PUT';
      }
      const response = await axios({
        method: method,
        url: url,
        data: body,
      });
      if (response.status === 200 || response.status === 201) {
        if (onEditIngredient) {
          onEditIngredient({ ...body, id: ingredientId }); // Call the callback function provided by parent component
        }
        console.log('Ingredient saved successfully!');
      } else {
        console.error('Error saving ingredient:', response.statusText);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setId('');
    setIngredients('');
    setDescription('');
    setQuantity('');
    setPrice('');
  };

  const handleModify = async () => {
    // Implement modification logic here (call handleSave with appropriate data)
    console.log('Modify ingredient');
    handleSave(); // Assuming data is already set for modification
  };

  return (
    <div style={{ display: 'flex' }}>
      {showSidebar && <Sidebar />}
      <div style={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            <IconButton edge="start" color="inherit" aria-label="menu">
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" style={{ flexGrow: 1 }}>
              Home Thali
            </Typography>
            <img src="/path-to-logo.png" alt="Logo" style={{ height: '40px' }} />
          </Toolbar>
        </AppBar>
        <Container maxWidth="md" style={{ padding: '20px' }}>
          <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
            <Typography variant="h4" component="h2" gutterBottom>
              Ingredients
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  label="ID"
                  value={id}
                  onChange={(e) => setId(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Ingredients"
                  value={ingredients}
                  onChange={(e) => setIngredients(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Quantity"
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Price"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Market Price"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  fullWidth
                />
              </Grid>
              
              <Grid item xs={12}>
                <Button variant="contained" color="primary" onClick={handleSave} style={{ marginRight: '10px' }}>
                  Save
                </Button>
                <Button variant="contained" color="secondary" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button variant="contained" color="primary" onClick={handleModify}style={{margin:'1vh'}}>
                  Modify
                </Button>
                
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </div>
    </div>
  );
};

export default IngredientsForm;

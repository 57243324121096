import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import {
  Box,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Collapse,
  Typography,
  IconButton,
  CircularProgress,
  Alert,
} from '@mui/material';
import { Search, Edit, Delete, Add } from '@mui/icons-material';
import debounce from 'lodash/debounce'; // For debouncing search

const BASE_URL = 'https://pricingappbackend.homemadefoodshop.com/Ingcategory'; // Set base URL

const IngredientCategories = () => {
  const [categories, setCategories] = useState([]);
  const [open, setOpen] = useState(false);
  const [newCategory, setNewCategory] = useState({ ingcategoryname: '', ingcategorydesc: '' });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null); // State for success messages
  const [searchTerm, setSearchTerm] = useState('');
  const [editingCategory, setEditingCategory] = useState(null);

  useEffect(() => {
    fetchCategories();
  }, []);

  // Fetch categories with debouncing
  const fetchCategories = async (search = '') => {
    try {
      setLoading(true);
      const response = await axios.get(`${BASE_URL}/getallcategories`);
      setCategories(response.data.data || []);
      setLoading(false);
    } catch (error) {
      console.error('Failed to fetch categories:', error);
      setError('Failed to fetch categories');
      setLoading(false);
    }
  };

  // Debounce the search function to reduce API calls
  const debouncedSearch = useCallback(
    debounce(async (searchTerm) => {
      fetchCategories(searchTerm);
    }, 500),
    []
  );

  // Handle the search term change
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
  };

  const handleSearchClick = () => {
    debouncedSearch(searchTerm);
  };

  const handleAddClick = () => {
    setOpen(!open);
    setEditingCategory(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewCategory({ ...newCategory, [name]: value });
  };

  const handleSubmit = async () => {
    try {
      if (editingCategory) {
        // Edit existing category
        await axios.put(`${BASE_URL}/modifyingcategory`, {
          ingcategoryid: editingCategory.ingcategoryid, // Include ID for editing
          ...newCategory,
        });
        setSuccess('Category updated successfully!');
      } else {
        // Add new category
        await axios.post(`${BASE_URL}/addingcategory`, newCategory);
        setSuccess('Category added successfully!');
      }
      await fetchCategories(); // Fetch with the current search term
      setNewCategory({ ingcategoryname: '', ingcategorydesc: '' });
      setOpen(false);
    } catch (error) {
      console.error('Failed to save category:', error);
      setError('Failed to save category');
    }
  };

  const handleEditClick = (category) => {
    setEditingCategory(category);
    setNewCategory({ ingcategoryname: category.ingcategoryname, ingcategorydesc: category.ingcategorydesc });
    setOpen(true);
  };

  const handleDeleteClick = async (categoryId) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this category?');
    if (confirmDelete) {
      try {
        await axios.delete(`${BASE_URL}/deleteingcategory?ingcategoryid=${categoryId}`);
        await fetchCategories(); // Fetch with the current search term
        setSuccess('Category deleted successfully!');
      } catch (error) {
        console.error('Failed to delete category:', error);
        setError('Failed to delete category');
      }
    }
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4" gutterBottom>Ingredient Categories</Typography>
      
      <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} alignItems="center" mb={2} gap={1}>
        <TextField
          label="Search Category"
          variant="outlined"
          size="small"
          value={searchTerm}
          onChange={handleSearchChange}
          sx={{ flexGrow: 1 }}
        />
        <Button variant="contained" onClick={handleSearchClick} startIcon={<Search />} sx={{ flexShrink: 0 }}>
          Search
        </Button>
        <Button variant="contained" onClick={handleAddClick} startIcon={<Add />} sx={{ flexShrink: 0 }}>
          {open ? 'Cancel' : 'Add'}
        </Button>
      </Box>

      {loading && <CircularProgress />}
      {error && <Alert severity="error">{error}</Alert>}
      {success && <Alert severity="success">{success}</Alert>} {/* Success Alert */}

      <Collapse in={open}>
        <Box display="flex" flexDirection="column" mb={2} gap={1}>
          <TextField
            label="Category Name"
            name="ingcategoryname"
            value={newCategory.ingcategoryname}
            onChange={handleChange}
            variant="outlined"
          />
          <TextField
            label="Category Description"
            name="ingcategorydesc"
            value={newCategory.ingcategorydesc}
            onChange={handleChange}
            variant="outlined"
          />
          <Button variant="contained" onClick={handleSubmit}>
            {editingCategory ? 'Update Category' : 'Add Category'}
          </Button>
        </Box>
      </Collapse>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {categories.map((category) => (
              <TableRow key={category.ingcategoryid}>
                <TableCell>{category.ingcategoryname}</TableCell>
                <TableCell>{category.ingcategorydesc}</TableCell>
                <TableCell>
                  <IconButton 
                    onClick={() => handleEditClick(category)} 
                    sx={{ color: 'blue' }} // Edit button color
                  >
                    <Edit />
                  </IconButton>
                  <IconButton 
                    onClick={() => handleDeleteClick(category.ingcategoryid)} 
                    sx={{ color: 'red' }} // Delete button color
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default IngredientCategories;
